<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 g">
          <button
            class="btn btn-relief-primary"
            v-b-modal.modal-add-device
          >
            <i class="fa fa-plus"></i>
            إضافة جهاز جديد
          </button>
          <!-- basic modal -->
          <b-modal id="modal-add-device" title="إضافة جهاز جديد" hide-footer>
            <b-overlay :show="loading">
              <div class="form-group">
                <label for="">العنوان (اسم الجهاز)</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="addDevice.title"
                  placeholder="اكتب هنا..."
                />
              </div>
              <div class="col-12 text-center">
                <button class="btn btn-primary" @click="addDeviceNow()">
                  إضافة الجهاز
                </button>
              </div>
            </b-overlay>
          </b-modal>
          <!-- basic modal -->
          <b-modal
            id="modal-qrcode"
            v-if="current_device.qrcode"
            title="اتصال الجهاز"
            hide-footer
          >
            <div class="text-center col-12">
              <img
                v-if="current_device.qrcode.includes('https')"
                :src="current_device.qrcode"
                style="width: 50%; margin: 0px auto"
              />
              <img
                v-if="!current_device.qrcode.includes('https')"
                :src="qrcodeaftergenerate"
                style="width: 50%; margin: 0px auto"
              />
            </div>
            <br />
            <p></p>
          </b-modal>
        </div>
        <div
          class="col-12 col-lg-6"
          v-for="device in devices"
          :key="device._id"
        >
          <div class="card card-body text-center device">
            <div class="row">
              <div class="col-4 d-none d-lg-block text-center g c">
                <img
                  :src="device.info.image"
                  style="
                    width: 100%;
                    margin: 0px auto;
                    border-radius: 50%;
                    object-fit: cover;
                  "
                  alt=""
                />
              </div>
              <div class="col-12 col-lg-8 text-left g c">
                <h4
                  style="
                    margin: 0;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                  "
                >
                  {{ device.title }}
                  <br />
                  <small class="text-muted">{{ device.info.bio }}</small>
                </h4>
              </div>
            </div>
            <div class="card-footer text-left">
              <div class="col-12 table-responsive">
                <table class="table table-hover">
                  <tbody>
                    <tr>
                      <td>العنوان</td>
                      <td>{{ device.title }}</td>
                    </tr>
                    <tr>
                      <td>حالة الإتصال</td>
                      <td>
                        <span
                          class="badge bg-warning"
                          v-if="device.status == 0"
                        >
                          <i class="fa fa-clock-o"></i>
                          لم يتصل بعد
                        </span>
                        <span
                          class="badge bg-success"
                          v-if="device.status == 1"
                        >
                          <i class="fa fa-check"></i>
                          متصل الآن
                        </span>
                        <span class="badge bg-danger" v-if="device.status == 2">
                          <i class="fa fa-times"></i>
                          قُطع الاتصال
                        </span>
                        <span class="badge bg-danger" v-if="device.status == 3">
                          <i class="fa fa-times"></i>
                          الرقم جديد!ّ ربما يتم حظره اذا تم الارسال منه
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>رقم الهاتف</td>
                      <td>{{ device.phone }}</td>
                    </tr>
                    <tr>
                      <td>
                        <button
                          v-if="device.status == 0"
                          class="btn btn-sm text-success"
                          @click="current_device = device"
                          v-b-modal.modal-qrcode
                        >
                          <i class="fa fa-qrcode"></i>
                          إتصال / فحص الجهاز
                        </button>
                      </td>
                      <td>
                        <button
                          class="btn btn-sm text-danger"
                          @click="deleteDevice(device._id)"
                        >
                          <i class="fa fa-trash"></i>
                          حذف الجهاز
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button
                  class="btn btn-block btn-default"
                  @click="restart(device._id)"
                  v-if="device.status == 1"
                >
                  <i class="fa fa-refresh"></i>
                  إعادة تشغيل الخدمة
                </button>
                <button
                  class="btn btn-block btn-default btn-success"
                  @click="clean(device._id)"
                  v-if="device.status == 2 || device.status == 3"
                >
                  <i class="fa fa-qrcode"></i>
                  إعادة الفحص من جديد
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BOverlay, BModal, VBModal } from "bootstrap-vue";
var QRCode = require("qrcode");
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      loading: true,
      addDevice: {},
      current_device: {},
      devices: [],
      inertValDevices: null,
      qrcodeaftergenerate: null,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  beforeDestroy() {
    if (this.inertValDevices) {
      clearInterval(this.inertValDevices);
    }
  },
  created() {
    var g = this;
    g.getDevices();
    g.inertValDevices = setInterval(() => {
      if (g.current_device) {
        g.devices.forEach(async (element) => {
          if (g.current_device._id == element._id) {
            g.current_device = JSON.parse(JSON.stringify(element));
            g.qrcodeaftergenerate = await QRCode.toDataURL(
              g.current_device.qrcode
            );
          }
        });
      }
      g.getDevices(true);
    }, 5000);
  },
  methods: {
    getDevices(noload = false) {
      var g = this;
      if (!noload) {
        g.loading = true;
      }
      $.post(api + "/user/devices/list", {
        jwt: this.user.jwt,
      })
        .then(function (r) {
          g.devices = r.response;
          g.loading = false;
        })
        .catch(function () {
          g.loading = false;
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدثت مشكلة في الاتصال",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
    addDeviceNow() {
      var g = this;
      if (this.addDevice.title && this.addDevice?.title?.trim() != "") {
        this.loading = true;
        $.post(api + "/user/devices/add", {
          jwt: g.user.jwt,
          title: g.addDevice.title,
        })
          .then(function (r) {
            g.loading = false;
            if (r.status == 100) {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "تم اضافة الجهاز بنجاح",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              location.reload();
            } else {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: r.response,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            g.loading = false;
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدثت مشكلة في الاتصال",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          });
      } else {
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "برجاء كتابة العنوان",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      }
    },
    deleteDevice(id) {
      var g = this;
      if (confirm("متأكد من حذف الجهاز؟")) {
        $.post(api + "/user/devices/delete", {
          jwt: g.user.jwt,
          id: id,
        })
          .then(function () {
            g.getDevices();
          })
          .catch(function () {
            alert("حدث خطأ");
          });
      }
    },
    restart(id) {
      var g = this;
      if (confirm("متأكد من اعادة تشغيل الخدمة؟")) {
        $.post(api + "/user/devices/restart", {
          jwt: g.user.jwt,
          id: id,
        })
          .then(function () {
            g.getDevices();
          })
          .catch(function () {
            alert("حدث خطأ");
          });
      }
    },
    clean(id) {
      var g = this;
      if (confirm("متأكد من اعادة الفحص من جديد؟")) {
        $.post(api + "/user/devices/clean", {
          jwt: g.user.jwt,
          id: id,
        })
          .then(function () {
            g.getDevices();
          })
          .catch(function () {
            alert("حدث خطأ");
          });
      }
    },
  },
};
</script>

<style>
.device {
  --border-size: 3px;
  --border-angle: 0turn;
  background-image: conic-gradient(
      from var(--border-angle),
      #fff,
      #ddd 50%,
      #ffffff
    ),
    conic-gradient(from var(--border-angle), transparent 20%, #08f, #f03);
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-animation: bg-spin 5s linear infinite;
  animation: bg-spin 5s linear infinite;
}
@-webkit-keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}
</style>